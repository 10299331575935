import { apiDelete, apiGet, apiPost } from '../api';
import { getModelInfo } from './model';
import { getBrothelInfo } from './company';
import { IUserInfo } from '../types/account';
import { createEvent, createStore } from 'effector';
import { IAddress } from '../types/address';
import { login, logout } from './auth';

export const updateUserInfo = createEvent<IUserInfo>();
export const clearUserInfo = createEvent();

export const $userInfo = createStore<IUserInfo | null>( null )
  .on( updateUserInfo, ( state, newState ) => {
    updateAddress( {
      cityId: newState.cityId,
      city: newState.city,
      address: newState.address
    } );

    return newState;
  } )
  .reset( clearUserInfo );

login.watch( state => {
  if ( state ) getUserInfo().then();
} );

logout.watch( state => {
  clearUserInfo();
} );


export const updateAddress = createEvent<IAddress>();
export const clearAddress = createEvent();

export const $address = createStore<IAddress>( { cityId: 0, city: '', address: '' } )
  .on( updateAddress, ( state, newState ) => newState )
  .reset( clearAddress );

export const getUserInfo = async () => {
  const response = await apiGet( {
    url: '/account',
  } );

  updateUserInfo( response );

  if ( response.isModel ) await getModelInfo();
  if ( response.isCompany ) await getBrothelInfo();

  return response;
};

export const saveUserInfo = async ( postData: IUserInfo | FormData ) => {
  const response = await apiPost( {
    url: '/account',
    postData
  } );

  updateUserInfo( response );

  return response;
};

export const saveUserAddress = async ( postData: any ) => {
  const response = await apiPost( {
    url: '/address',
    postData
  } );

  updateUserInfo( response );

  return response;
};

export const checkAddress = async ( postData: any ) => {
  const response = await apiPost( {
    url: '/checkAddress',
    postData
  } );

  if ( response.cityId ) {
    updateAddress( response );
  }

  return response;
};

export const confirmEmail = async ( token: string ) => {
  return await apiGet( {
    url: `/confirm/${ token }`,
  } );
};

export const getFavorites = async () => {
  return await apiGet( {
    url: '/favorites'
  } );
};

export const addFavorite = async ( id: string ) => {
  const response = await apiPost( {
    url: `/favorites/${ id }`,
    postData: {}
  } );

  updateUserInfo( response );

  return response;
};

export const deleteFavorite = async ( id: string ) => {
  const response = await apiDelete( {
    url: `/favorites/${ id }`,
    postData: {}
  } );

  updateUserInfo( response );

  return response;
};
