import React, { useEffect, useState } from 'react';
import styles from './OrderModelAccept.module.scss';
import { Modal } from '../Modal';
import { EModal_VisibleStore } from '../../../../types/modal';
import SimpleButton from '../../Buttons/Button/Button';
import { Rating } from 'react-simple-star-rating';
import { localization } from '../../../../data/localization';
import { useStore } from 'effector-react';
import { $cartWait, clearCartWait, confirmCartWaiting, rejectCartWaiting } from '../../../../store/cart';
import { $modelInfo } from '../../../../store/model';
import Moment from 'react-moment';
import { timeToString } from '../../../../util/date';
import { ACCEPT_WAIT_TIME_MINUTES, ICartDetailProduct } from '../../../../types/cart';
import moment from 'moment';
import { updatePreloader } from '../../../../store/preloader';
import { staticResource } from '../../../../util/resources';

const OrderModelAccept = () => {
  const cartWaiting = useStore( $cartWait );
  const [ timer, setTimer ] = useState( 0 );

  const _questionnaire = useStore( $modelInfo );

  const updateTimer = () => {
    const waitTime = moment.duration(
      moment( cartWaiting?.updatedAt )
        .add( ACCEPT_WAIT_TIME_MINUTES, 'minutes' )
        .diff( moment() )
    );
    setTimer( Math.floor( waitTime.asSeconds() ) );
  };

  useEffect( () => {
    if ( cartWaiting?.customer.id ) {
      updateTimer();
    }
  }, [ cartWaiting?.customer.id ] );

  useEffect( () => {
    if ( cartWaiting?.customer.id && timer > 0 ) {
      setTimeout( updateTimer, 1000 );
    }
  }, [ timer ] );

  if ( !cartWaiting || timer <= 0 ) return <></>;

  const questionnaire: ICartDetailProduct | undefined = cartWaiting?.products.find( p => p.questionnaireId === _questionnaire?.id );

  const acceptOrder = async () => {
    if ( questionnaire ) {
      updatePreloader( true );
      await confirmCartWaiting( questionnaire );
      clearCartWait();
    }
  };

  const rejectOrder = async () => {
    if ( questionnaire ) {
      updatePreloader( true );
      await rejectCartWaiting( questionnaire );
      clearCartWait();
    }
  };

  return (
    <Modal
      storeKey={ EModal_VisibleStore.OrderModelAccept }
      modalClass={ styles.modalClass }
      wrapperClass={ styles.wrapperClass }
      cantClose={ true }
    >
      <div>
        <div className={ styles.top }>
          <img src={ staticResource( cartWaiting?.customer.image, 100, 100 ) } alt="" className={ styles.avatar }/>

          <div className={ styles.infoWrapper }>
            <a
              href={ `/clients/${ cartWaiting.customer.id }` }
              className={ styles.bold }
              target="_blank" rel="noreferrer"
            >{ cartWaiting?.customer.username }, { cartWaiting?.customer.age } y.o.</a>
            <div className={ styles.rating }>
              <Rating
                readonly
                fillColor={ '#FF1178FF' }
                emptyColor={ '#fff' }
                size={ 25 }
                initialValue={ cartWaiting?.customer.rating }
              />
            </div>
            <div className={ styles.text }>{ cartWaiting?.deliveryAddress }</div>
            <div className={ styles.borderedText }>
              { questionnaire?.needDelivery ?
                localization.profile.houseCall
                :
                localization.profile.apartments
              }
            </div>

            { questionnaire?.options?.map( option =>
              <div key={ option.serviceId } className={ styles.text }>{ option.name }</div>
            ) }

          </div>

          <div className={ styles.dateWrapper }>
            {/*<div className={ styles.pink }>500m { localization.profile.toYou }</div>*/ }
            <div className={ styles.text }>
              <Moment format="DD/MM/YYYY HH:mm">{ questionnaire?.arrivalTime }</Moment>
            </div>
            <div className={ styles.bold }>{ questionnaire?.time }</div>
          </div>

          <div className={ styles.timeWrapper }>
            {/*<div className={ styles.text }>5:20-7:20 pm</div>*/ }
            <div className={ styles.bold }>${ questionnaire?.price } { localization.profile.totalPrice }</div>
          </div>
        </div>

        <div className={ styles.divider }/>

        <div className={ styles.down }>
          <div className={ styles.time }>{ timeToString( timer ) }</div>
          <div className={ styles.btnsWrapper }>
            <SimpleButton
              text={ localization.buttons.accept }
              className={ styles.btnClass }
              onClick={ acceptOrder }
            />
            <SimpleButton
              text={ localization.buttons.cancel }
              className={ styles.btnClass }
              onClick={ rejectOrder }
            />
          </div>

        </div>
      </div>
    </Modal>
  );
};

export default OrderModelAccept;
