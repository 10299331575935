export const staticResource = ( link?: string, width?: number, height?: number ) => {
  if ( !link ) return '';

  // eslint-disable-next-line no-undef
  const baseUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_API_URL;

  let _link = link.split( '.' );
  let ext = _link.pop();

  if ( width || height ) {
    link = _link.join( '.' ) + `-${ width }x${ height }.${ ext }`;
  } else {
    link = _link.join( '.' ) + `.${ ext }`;
  }

  return baseUrl + ( `/storage/${ link }`.replace( /\/+/g, '/' ) );
};
