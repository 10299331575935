import React, { useEffect, useRef, useState } from 'react';
import { staticResource } from '../../../../util/resources';
import styles from './VideoPlayer.module.scss';
import playBtn from '../../../../static/imgs/playBtn.svg';
import pauseBtn from '../../../../static/imgs/pauseBtn.svg';
import cn from 'classnames';

interface IProps {
  video: any
}

const VideoPlayer = ( { video }: IProps ) => {
  const ref = useRef<HTMLVideoElement>( null );
  const [ play, setPlay ] = useState<boolean>( false );

  const onPlay = () => {
    if ( ref.current?.paused ) {
      ref.current?.play();
      setPlay( true );
    } else {
      ref.current?.pause();
      setPlay( false );
    }
  };

  useEffect( () => {

  }, [] );

  return (
    <div className={ styles.videoWrapper }>
      <div className='pos br-15'>
        { video &&
          <video
            ref={ ref }
            key={ video }
            width={ 300 }
            height={ 500 }
            onEnded={ () => setPlay( false ) }
          >
            <source src={ staticResource( video ) }/>
          </video>
        }
        { video &&
          <div className={ styles.videoBtnWrapper }>
            <button onClick={ onPlay } className={ cn( styles.playBtn, play ? '' : styles.on ) }>
              <img src={ !play ? playBtn : pauseBtn } alt=""/>
            </button>
          </div>
        }

      </div>
    </div>
  );
};

export default VideoPlayer;
