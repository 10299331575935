import React from 'react';
import styles from './HomeLayoutApp.module.scss';
import HeaderHome from '../../Headers/HeaderHome/HeaderHome';
import SearchBarMain from '../../SearchBar/Main/SearchBarMain';
import MainStack from '../../../navigator/MainStack';
import FooterHome from '../../Footer/FooterHome/FooterHome';
import { useStore } from 'effector-react';
import { $userInfo } from '../../../../store/account';

const HomeLayoutApp = () => {
  const userInfo = useStore( $userInfo );

  return (
    <div className={ styles.container }>

      <header className={ styles.header }>
        <HeaderHome/>
      </header>

      { location.pathname === '/' && <div className={ styles.searchBar }><SearchBarMain/></div> }

      <main className={ styles.main }>
        <MainStack/>
      </main>

      <footer className={ styles.footer }>
        <FooterHome/>
      </footer>
    </div>
  );
};

export default HomeLayoutApp;
