import React from 'react';
import styles from './FooterHome.module.scss';
import { Link } from 'react-router-dom';
import twitter from '../../../../static/imgs/icons/twitter.svg';
import insta from '../../../../static/imgs/icons/insta.svg';
import telegram from '../../../../static/imgs/icons/teleg.svg';
import { localization } from '../../../../data/localization';

const FooterHome = () => {
  const year = new Date().getFullYear();

  return (
    <div className={ styles.footer }>
      <div className={ styles.rights }>HB, 2022-{ year } <br/> { localization.footer.rights }</div>
      <div className={ styles.tradeMark }>
        { localization.footer.text }
      </div>
      <ul className={ styles.socialList }>
        <li className={ styles.socialItem }>
          <Link to={ '/' }><img src={ twitter } alt={ localization.common.happyBunny }/></Link>
        </li>
        <li className={ styles.socialItem }>
          <Link to={ '/' }><img src={ insta } alt={ localization.common.happyBunny }/></Link>
        </li>
        <li className={ styles.socialItem }>
          <Link to={ '/' }><img src={ telegram } alt={ localization.common.happyBunny }/></Link>
        </li>
      </ul>
    </div>
  );
};

export default FooterHome;


