import React from 'react';
import OrderModelAccept from '../Modal/OrderModelAccept/OrderModelAccept';
import OrderClientAccept from '../Modal/OrderClientAccept/OrderClientAccept';
import { showModal } from '../../../store/modal';
import { EModal_VisibleStore } from '../../../types/modal';
import PaymentModal from './PaymentModal/PaymentModal';

const ModalWrapper = () => {
  // const showModalStore = useStore($modalVisible);

  showModal( EModal_VisibleStore.OrderModelAccept );
  showModal( EModal_VisibleStore.OrderClientAccept );

  return (
    <>
      <OrderModelAccept/>
      <OrderClientAccept/>
      <PaymentModal/>
    </>
  );
};

export default ModalWrapper;
