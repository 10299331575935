import React, { useEffect, useState } from 'react';
import styles from './OrderClientAccept.module.scss';
import { EModal_VisibleStore } from '../../../../types/modal';
import { Modal } from '../Modal';
import { localization } from '../../../../data/localization';
import { ACCEPT_WAIT_TIME_MINUTES, ICartStatus } from '../../../../types/cart';
import { useStore } from 'effector-react';
import { $cart, saveCart } from '../../../../store/cart';
import { timeToString } from '../../../../util/date';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';

const OrderClientAccept = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const cart = useStore( $cart );
  const [ timer, setTimer ] = useState( -1 );

  const updateTimer = () => {
    const waitTime = moment.duration(
      moment( cart?.updatedAt )
        .add( ACCEPT_WAIT_TIME_MINUTES, 'minutes' )
        .diff( moment() )
    );

    setTimer( Math.floor( waitTime.asSeconds() ) );

    return waitTime.asSeconds();
  };

  useEffect( () => {
    if ( cart && cart.status !== ICartStatus.CREATED && updateTimer() > 0 ) {
      if ( location.pathname !== '/cart' ) {
        navigate( '/cart' );
      }
    }
  }, [ cart?.status ] );

  useEffect( () => {
    if ( cart && cart.status !== ICartStatus.CREATED && timer > 0 ) {
      setTimeout( updateTimer, 1000 );
    }

    if ( cart && cart.status !== ICartStatus.CREATED && timer === 0 ) {
      saveCart( { status: ICartStatus.CREATED } ).then();
    }
  }, [ timer ] );

  if ( !cart || cart.status !== ICartStatus.WAITING || timer <= 0 ) return <></>;

  return (
    <Modal storeKey={ EModal_VisibleStore.OrderClientAccept } cantClose={ true }>
      <div className={ styles.wrapper }>
        <div className={ styles.time }>{ timeToString( timer ) }</div>
        <div className={ styles.text }>{ localization.common.pleaseWait }</div>
      </div>
    </Modal>
  );
};

export default OrderClientAccept;
