import { DetailedHTMLProps, InputHTMLAttributes } from 'react';

export interface IInputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  inputClass?: string;
  inputRef?: any;
  width?: number;
  labelRef?: any;
}

const FormInput = (
  {
    labelRef,
    inputRef,
    style,
    inputClass,
    className,
    width,
    type = 'text',
    ...props
  }: IInputProps ) => {
  return (
    <label ref={ labelRef } className={ className } style={ style }>
      <span className="pos d-block">
        <div style={ { position: 'relative' } }>
          <input
            ref={ inputRef }
            type={ type }
            style={ { width } }
            className={ inputClass }
            { ...props }
          />
        </div>
      </span>
    </label>
  );
};

export default FormInput;
