import React from 'react';
import styles from './SearchBarkAll.module.scss';
import { localization } from '../../../../data/localization';
import { usePlacesWidget } from 'react-google-autocomplete';
import { updateFilter } from '../../../../store/filter';
import { useNavigate } from 'react-router-dom';
import { IWidget } from '../../../../types/google';
import { v4 as uuidv4 } from 'uuid';

const SearchBarAll = () => {
  const navigate = useNavigate();
  const { ref: inputRef, autocompleteRef }: IWidget = usePlacesWidget( {
    // eslint-disable-next-line no-undef
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    libraries: [ 'places', 'geocoding' ],
    options: {
      types: []
    },
    onPlaceSelected: async ( place ) => {
      let _adr = place.formatted_address.split( ',' );
      let address: any = {
        address: [ _adr[ 0 ], _adr[ 1 ] ].join( ',' ),
        lat: place.geometry.location.lat(),
        lon: place.geometry.location.lng()
      };
      updateFilter( { address: address } );
      navigate( '/' );
    }
  } );

  const change = ( e: any ) => {
    if ( !e.target.value ) {
      updateFilter( { address: undefined } );
    }
  };

  return (
    <div className={ styles.wrapper }>
      <form className={ styles.form }>
        <div className={ styles.inputWrapper }>
          <input
            id={ uuidv4() }
            className={ styles.input }
            type="text"
            ref={ inputRef }
            onChange={ change }
          />
          <input
            className={ styles.inputBtn }
            type='submit'
            value={ localization.buttons.search }
          />
        </div>
      </form>
    </div>
  );
};

export default SearchBarAll;
