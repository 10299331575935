import React from 'react';
import styles from './Prices.module.scss';
import { IQuestionnaire } from '../../../../../types/questionnaires';
import SexMenu from '../../../ModalDown/SexMenu/SexMenu';
import TabContainer, { ITabsButton } from '../../../Tabs/TabContainer';
import { TabPanel } from 'react-tabs';
import { localization } from '../../../../../data/localization';
import { ICartProduct } from '../../../../../types/cart';
import { clone } from '../../../../../util/util';
import { useStore } from 'effector-react';
import { $cart } from '../../../../../store/cart';
import Spinner from '../../../Spinner/Spinner';

interface IProps {
  code: 'priceInApartment' | 'priceInDeparture';
  questionnaire?: IQuestionnaire | null;
  selected?: ICartProduct;
  setSelected?: ( value: ICartProduct ) => void;
  needDelivery?: boolean;
}

const Prices = ( { code, questionnaire, selected, setSelected, needDelivery }: IProps ) => {
  const cart = useStore( $cart );
  let tabs: ITabsButton[] = [];

  const prices: ICartProduct[] = [];

  questionnaire?.prices?.forEach( price => {
    prices.push( {
      questionnaireId: questionnaire.id || '',
      priceId: price.id,
      price: price[ code ],
      options: [],
      needDelivery: needDelivery
    } );
    tabs.push( {
      value: `${ price.time }_${ price[ code ] }`,
      label: `${ price.time } ${ price[ code ] }`
    } );
  } );

  const onSelect = ( index: number ) => {
    if ( setSelected && prices[ index ] ) {
      setSelected( clone( prices[ index ] ) );
    }
  };

  let selectedIndex = 0;
  questionnaire?.prices?.forEach( ( price, index ) => {
    if ( price.time === selected?.time ) selectedIndex = index;
  } );

  if ( !selected && setSelected ) return <div><Spinner/></div>;

  return (
    <div className='d-flex'>
      <div className={ styles.priceTitle }>{ localization.profile.price }</div>

      <TabContainer
        tabs={ tabs }
        onSelect={ onSelect }
        defaultIndex={ selectedIndex }
        forceRenderTabPanel={ false }
      >
        { questionnaire?.prices?.map( price =>
          <TabPanel key={ price.id }>
            <SexMenu price={ price } selected={ selected } setSelected={ setSelected }/>
          </TabPanel>
        ) }
      </TabContainer>

    </div>
  );
};

export default Prices;
