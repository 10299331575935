import React from 'react';
import styles from './AdditionalButtons.module.scss';
import { localization } from '../../../data/localization';
import ColoredButton from '../Buttons/ColoredButton/ColoredButton';
import FavoriteIcon from '../Sliders/ModelCard/FavoriteIcon';
import { IQuestionnaire } from '../../../types/questionnaires';
import { ICartProduct } from '../../../types/cart';
import { addQuestionnaire } from '../../../store/cart';
import { useNavigate } from 'react-router-dom';
import { updatePreloader } from '../../../store/preloader';

interface IProps {
  questionnaire: IQuestionnaire;
  selected?: ICartProduct;
}

const AdditionalButtons = ( { questionnaire, selected }: IProps ) => {
  const navigate = useNavigate();

  const addToCart = async () => {
    if ( selected ) {
      updatePreloader( true );
      await addQuestionnaire( selected );
      navigate( '/cart' );
    }
  };

  return (
    <>
      <div className={ styles.additionalBtns }>
        {/*<div className={ styles.distance }>500m { localization.profile.toYou }</div>*/ }

        <FavoriteIcon questionnaire={ questionnaire }/>

        {/*<Link to={ '/' } className='ml-30'>*/ }
        {/*  <img width="25" height="25" src={ bag } alt="Cart" className={ styles.iconBag }/>*/ }
        {/*</Link>*/ }
      </div>

      <ColoredButton onClick={ addToCart } text={ localization.buttons.orderNow } className={ styles.orderNowBtn }/>
    </>
  );
};

export default AdditionalButtons;
