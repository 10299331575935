import React from 'react';
import AllLayoutApp from './AllLayoutApp/AllLayoutApp';
import HomeLayoutApp from './HomeLayoutApp/HomeLayoutApp';
import { useStore } from 'effector-react';
import { $auth } from '../../../store/auth';
import { useLocation } from 'react-router-dom';
import { $address } from '../../../store/account';

const ThemeDependence = () => {
  const address = useStore( $address );
  const auth = useStore( $auth );
  const location = useLocation();

  const homeLayout = ( location.pathname === '/' && !address.cityId ) ||
    location.pathname === '/login' ||
    location.pathname === '/registration' ||
    location.pathname === '/registration_seller';

  return (
    <>
      { homeLayout ?
        <HomeLayoutApp/>
        :
        <AllLayoutApp/>
      }
    </>
  );
};

export default ThemeDependence;
