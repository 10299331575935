import React from 'react';
import styles from './SearchBarMain.module.scss';
import { usePlacesWidget } from 'react-google-autocomplete';
import { checkAddress, saveUserAddress } from '../../../../store/account';
import { localization } from '../../../../data/localization';
import { useStore } from 'effector-react';
import { $auth } from '../../../../store/auth';
import { IWidget } from '../../../../types/google';
import { v4 as uuidv4 } from 'uuid';

const SearchBarMain = () => {
  const auth = useStore( $auth );

  const { ref, autocompleteRef }: IWidget = usePlacesWidget( {
    // eslint-disable-next-line no-undef
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    libraries: [ 'places', 'geocoding' ],
    options: {
      types: []
    },
    onPlaceSelected: async ( place ) => {
      if ( auth ) {
        await saveUserAddress( place );
      } else {
        await checkAddress( place );
      }
    }
  } );

  return (
    <div className={ styles.wrapper }>
      <p className={ styles.p }>{ localization.common.enterLocation }</p>
      <form className={ styles.form }>
        <div className={ styles.inputWrapper }>
          <input ref={ ref } id={ uuidv4() } className={ styles.input } type="text"/>
          <input className={ styles.inputBtn } type='submit' value={ localization.buttons.search }/>
        </div>
      </form>
    </div>
  );
};

export default SearchBarMain;
