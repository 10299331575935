import React from 'react';
import styles from './SexMenu.module.scss';
import IncludedServices from '../../SexItems/IncludedServices/IncludedServices';
import SexItems from '../../SexItems/SexItems/SexItems';
import ColoredButton from '../../Buttons/ColoredButton/ColoredButton';
import { IQuestionnairePrice } from '../../../../types/questionnaires';
import { localization } from '../../../../data/localization';
import { ICartProduct } from '../../../../types/cart';

interface IProps {
  price?: IQuestionnairePrice | null;
  selected?: ICartProduct;
  setSelected?: ( value: ICartProduct ) => void;
}

const SexMenu = ( { price, selected, setSelected }: IProps ) => {
  return (
    <div className={ styles.modalWrapper }>
      <div className={ styles.divider }/>
      <div className={ styles.title }>{ localization.profile.services }</div>
      <IncludedServices price={ price }/>

      <div className={ styles.divider }/>

      <div className={ styles.inner }>
        <div className={ styles.title }>{ localization.profile.addItems }</div>
        <div className={ styles.wrapper }>

          { price?.serviceGroups.filter( s => s.services.find( j => j.price > 0 ) ).map( serviceGroup =>
            <div key={ serviceGroup.name }>
              <div className={ styles.titleItems }>{ serviceGroup.name }</div>
              <SexItems serviceGroup={ serviceGroup } selected={ selected } setSelected={ setSelected }/>
            </div>
          ) }

        </div>
      </div>

      <div className={ styles.divider }/>
      { !price &&
        <div className={ styles.sexMenuFooter }>
          <ColoredButton text={ 'order now' } className={ styles.btnClass }/>
          <div className={ styles.totalPrice }>total price $2900</div>
        </div>
      }

    </div>
  );
};

export default SexMenu;
