import React, { useEffect, useState } from 'react';
import Layout from './components/layouts/Layout';
import { $auth } from './store/auth';
import { $userInfo, getUserInfo } from './store/account';
import { useStore } from 'effector-react';
import { $config, getConfig } from './store/config';
import { useTheme } from './hooks/useTheme';
import { $cart, getCart, getCartWaiting } from './store/cart';

import './channels/general';
import { initializeEcho } from './channels/general';
import Spinner from './components/common/Spinner/Spinner';

function App() {
  const [ isLoading, setIsLoading ] = useState( true );
  const auth = useStore( $auth );
  const userInfo = useStore( $userInfo );
  const config = useStore( $config );
  useStore( $cart );

  useTheme();

  useEffect( () => {
    let wi = 390;
    let width = window.outerWidth;
    let scale = width / wi;
    if ( width < 390 ) {
      //@ts-ignore
      document.querySelector( '[name="viewport"]' )
        .setAttribute(
          'content',
          'width=' + wi + ', initial-scale=' + scale + ', maximum-scale=' + scale + ', shrink-to-fit=no, user-scalable=1'
        );
    }

    getConfig().then();
  }, [] );

  useEffect( () => {
    if ( auth ) {
      getUserInfo().then( () => setIsLoading( false ) );
      getCart().then();
    }
    if ( userInfo?.id ) initializeEcho( userInfo );
    if ( userInfo?.isModel ) getCartWaiting().then();
  }, [ userInfo?.id, auth ] );

  if ( !config.languages?.length || ( isLoading && auth ) ) {
    return <Spinner/>;
  }

  return (
    <Layout/>
  );
}

export default App;
