import moment from 'moment';

export function momentjs( date?: any ): moment.Moment {
  if ( !date ) return moment();
  if ( typeof date === 'number' ) return moment.unix( date );
  if ( typeof date === 'string' ) return moment( date );
  if ( typeof date === 'object' && date._isAMomentObject ) return date;
  return moment( date );
}

export const timeToString = ( _seconds: number ) => {
  let minutes: any = Math.floor( _seconds / 60 );
  let seconds: any = _seconds - ( minutes * 60 );
  if ( minutes < 10 ) minutes = `0${ minutes }`;
  if ( seconds < 10 ) seconds = `0${ seconds }`;

  return `${ minutes }:${ seconds }`;
};
