import React from 'react';
import styles from './Balance.module.scss';
import { useStore } from 'effector-react';
import { $userInfo } from '../../../store/account';
import UpBalance from './UpBalance';
import WithDrawBalance from './WithDrawBalance';

const Balance = () => {
  const userInfo = useStore( $userInfo );

  return (
    <div className='w-100-p profilePageWrapper'>
      <div className={ styles.divider }/>
      <div className={ styles.email }>{ userInfo?.email }</div>

      <div className="d-flex gap-10 flex-wrap">
        <UpBalance/>
        <WithDrawBalance/>
      </div>
    </div>
  );
};

export default Balance;
