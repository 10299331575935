import React, { useRef, useState } from 'react';
import styles from './Balance.module.scss';
import FormTextarea from '../../../components/common/Form/FormTextarea';
import FormInput from '../../../components/common/Form/FormInput';
import cn from 'classnames';
import { localization } from '../../../data/localization';
import ColoredButton from '../../../components/common/Buttons/ColoredButton/ColoredButton';
import { useStore } from 'effector-react';
import { $userInfo, getUserInfo } from '../../../store/account';
import { createWithdrawRequest, getWithdrawRequests } from '../../../store/withdrawRequests';
import { useQuery } from 'react-query';
import { EWithdrawStatus, IWithdrawRequests } from '../../../types/withdrawRequests';

const WithDrawBalance = () => {
  const userInfo = useStore( $userInfo );
  const input = useRef<any>( null );
  const textarea = useRef<any>( null );
  const [ disabled, setDisabled ] = useState( false );

  const {
    data: requests,
    isLoading,
    refetch
  } = useQuery<IWithdrawRequests>( [ 'withdrawRequests' ], getWithdrawRequests );

  const create = async () => {
    setDisabled( true );
    const amount = Number( input.current?.value );
    createWithdrawRequest( amount, textarea.current?.value )
      .then( () => {
        setDisabled( false );
        refetch();
        getUserInfo().then();
      } )
      .catch( () => setDisabled( false ) );
  };

  return (
    <>
      <div className={ styles.balanceWrapper }>
        <label className={ styles.email } style={ { marginBottom: 10 } }>{ localization.withdraw.label }</label>
        <FormTextarea
          inputRef={ textarea }
          style={ { width: '100%', height: '100%', maxWidth: '100%' } }
        />
        <label
          className={ cn( styles.email, styles.amountText ) }
          style={ { marginBottom: 0, marginTop: 'auto' } }
        >{ localization.withdraw.amount }</label>
        <FormInput
          inputRef={ input }
          inputClass={ cn( styles.inputClass, styles.wihtdrawInput ) }
          placeholder={ localization.common.enterValue }
          defaultValue={ userInfo?.balance }
          type={ 'number' }
        />
        <ColoredButton
          onClick={ create }
          text={ localization.buttons.withdrawBalance }
          className={ styles.btnClass }
          disabled={ disabled }
        />
      </div>
      <div className={ cn( styles.withdraw, 'd-flex flex-column' ) }>
        <label className={ styles.email }
               style={ { marginBottom: 10 } }>{ localization.withdraw.listTitle }</label>
        <table>
          <thead>
          <tr className="withdraw-item d-flex">
            <td className={ styles.withdrawText }>{ localization.withdraw.listAmount }</td>
            <td className={ styles.withdrawText }>{ localization.withdraw.listComment }</td>
            <td className={ styles.withdrawText }>{ localization.withdraw.listStatus }</td>
          </tr>
          </thead>
          <tbody>
          { requests?.items.map( r =>
            <tr key={ r.id } className="withdraw-item d-flex">
              <td className={ styles.withdrawText }>$ { r.amount }</td>
              <td className={ styles.withdrawText }>{ r.rejectComment }</td>
              <td className={ styles.withdrawText }>
                { r.status === EWithdrawStatus.CREATED_STATUS && localization.withdraw.wait }
                { r.status === EWithdrawStatus.CONFIRMED_STATUS && localization.withdraw.confirm }
                { r.status === EWithdrawStatus.REJECTED_STATUS && localization.withdraw.reject }
              </td>
            </tr>
          ) }
          </tbody>
        </table>
      </div>
    </>
  );
};

export default WithDrawBalance;
