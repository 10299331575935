import React from 'react';
import styles from './ViewInfoAbout.module.scss';
import { useStore } from 'effector-react';
import { $userInfo } from '../../../store/account';
import { $modelInfo } from '../../../store/model';
import InfoAboutAside from './InfoAboutAside/InfoAboutAside';
import InfoAboutMain from './InfoAboutMain/InfoAboutMain';
import Tags from './InfoAboutMain/Tags/Tags';
import CollapsedWrapper from '../RangeBar/CollapsedFilter/CollapsedWrapper';
import { localization } from '../../../data/localization';
import AboutMe from './InfoAboutAside/AboutMe/AboutMe';
import VideoPlayer from '../../../pages/Profile/Model/Video/VideoPlayer';
import PricesTabs from './InfoAboutMain/Prices/PricesTabs';

const ViewInfoAbout = () => {
  const questionnaire = useStore( $modelInfo );
  const account = useStore( $userInfo );

  return (
    <div className='profileViewWrapper'>
      <div className={ styles.dividerWrapper }>
        <div className={ styles.divider }/>
      </div>

      <div className={ styles.wrapper }>
        <InfoAboutAside questionnaire={ questionnaire }/>
        <div className={ styles.setting }>
          <div className={ styles.email }>{ account?.email }</div>
          { questionnaire &&
            <InfoAboutMain
              questionnaire={ questionnaire }
              hideButtons={ true }
            />
          }
        </div>
      </div>

      {/* Mobile start */ }
      <div className={ styles.tagsDesktop }>
        <Tags questionnaire={ questionnaire?.tags }/>
      </div>

      <div className={ styles.priceDesktop }>
        <PricesTabs questionnaire={ questionnaire }/>
      </div>

      <div className={ styles.collapseContainer }>
        <div className={ styles.collapseRow }>
          <CollapsedWrapper
            text={ localization.profile.aboutMe }
            styleText={ { color: '#FF1178', fontSize: 14, lineHeight: '19px' } }
            style={ { borderTop: '1px solid #FF1178' } }
            pink
          >
            <div className={ styles.aboutMeWrapper }>
              <AboutMe about={ questionnaire?.about }/>
            </div>

            <div className={ styles.videoInner }>
              <VideoPlayer video={ questionnaire?.video }/>
            </div>
          </CollapsedWrapper>
        </div>
      </div>
      {/* Mobile end */ }

      <div className={ styles.messages }>
        <div className={ styles.messageWrapper }>
          <div className={ styles.title }>Mr President</div>
          <div className={ styles.text }>Meeting class. The girl is really top. Not surprisingly, it was not easy to
            get there, but it was worth it.
            <br/>
            It is not possible to forget sex, it stays in your head for a long time
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewInfoAbout;
