export const getAppUrl = ( path?: string ) => {
  if ( !path ) path = '';
  if ( path && !path.startsWith( '/' ) ) path = '/' + path;

  // eslint-disable-next-line no-undef
  return ( process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_API_URL ) + path;
};

export const API = {
  // eslint-disable-next-line no-undef
  url: getAppUrl( '/api' ),
  timeout: 60000,
};
