import { createEvent, createStore } from 'effector';
import { IFilter } from '../types/filter';

export const updateFilter = createEvent<IFilter>();
export const clearFilter = createEvent();

export const $filter = createStore<IFilter>( {} )
  .on( updateFilter, ( state, payload ) => ( { ...state, ...payload, updated: true } ) )
  .reset( clearFilter );




