import React from 'react';
import styles from './PromoItem.module.scss';
import { IPromo } from '../../../../types/promo';
import FormCheckbox from '../../../../components/common/Form/FormCheckbox';
import { staticResource } from '../../../../util/resources';
import { localization } from '../../../../data/localization';
import { IQuestionnaire } from '../../../../types/questionnaires';
import { buyPromotions } from '../../../../store/promo';

interface IPromoProps {
  promo: IPromo;
  questionnaire: IQuestionnaire;
  setUpdate: ( value: number ) => void;
}

const PromoItem = ( { promo, questionnaire, setUpdate }: IPromoProps ) => {
  const buyPromo = ( price: number ) => async ( e: any ) => {
    e.preventDefault();
    if ( promo.selected ) return;

    if ( questionnaire.id ) {
      await buyPromotions( questionnaire.id, promo.id, price );
    }

    setUpdate( Date.now() );
  };

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.title }>{ promo.title }</div>
      <div className={ styles.description }>{ promo.subTitle }</div>
      <div className={ styles.imgWrapper }>
        <img src={ staticResource( promo.image ) } alt=""/>
      </div>
      {/*<FormCheckbox className={ styles.promoBtn } label={ promo.first }/>*/ }
      <FormCheckbox
        onClick={ buyPromo( promo.priceForWeek ) }
        checked={ promo.selected }
        className={ styles.promoBtn }
        label={ localization.profile.forWeek.replace( '[price]', String( promo.priceForWeek ) ) }
      />
      <FormCheckbox
        onClick={ buyPromo( promo.priceForMonth ) }
        checked={ promo.selected }
        className={ styles.promoBtn }
        label={ localization.profile.forMonth.replace( '[price]', String( promo.priceForMonth ) ) }
      />
    </div>
  );
};

export default PromoItem;
