import React, { useEffect } from 'react';
import './alert.scss';
import { useStore } from 'effector-react';
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineExclamationCircle,
  AiOutlineInfoCircle
} from 'react-icons/ai';
import { $alert, clearAlert } from '../../../store/alert';

const Alert = () => {
  const alert = useStore( $alert );

  useEffect( () => {
    if ( alert.message ) {
      setTimeout( () => {
        clearAlert();
      }, 7000 );
    }
  }, [ alert.message ] );

  if ( !alert.message ) {
    return <></>;
  }

  return (
    <div className="alert-container pointer d-flex" onClick={ () => {
      clearAlert();
    } }>
      <div className={ `alert-body alert-${ alert.type }` }>
        { alert.type === 'warning' && <div className='alert-icon'><AiOutlineExclamationCircle/></div> }
        { alert.type === 'danger' && <div className='alert-icon'><AiOutlineCloseCircle/></div> }
        { alert.type === 'success' && <div className='alert-icon'><AiOutlineCheckCircle/></div> }
        { alert.type === 'info' && <div className='alert-icon'><AiOutlineInfoCircle/></div> }
        { alert.message }
      </div>
    </div>
  );
};

export default Alert;
