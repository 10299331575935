import React from 'react';
import styles from './Spinner.module.scss';
import { Hearts } from 'react-loader-spinner';

const Spinner = () => {
  return (
    <Hearts
      height="80"
      width="80"
      color="#FF1178FF"
      ariaLabel="hearts-loading"
      wrapperStyle={ {} }
      wrapperClass={ styles.spinner }
      visible={ true }
    />
  );
};

export default Spinner;
