import React from 'react';
import { Modal } from '../Modal';
import { $paymentModal, paymentModalKey } from '../../../../store/payments';
import { useStore } from 'effector-react';
import styles from './PaymentModal.module.scss';
import Copy from '../../Copy/Copy';

const PaymentModal = () => {
  const payment = useStore( $paymentModal );


  return (
    <Modal
      storeKey={ paymentModalKey }
      // modalClass={ styles.modalClass }
      // wrapperClass={ styles.wrapperClass }
      // cantClose={ true }
    >

      <div className={ styles.container }>
        <div className='header-xmr-payment'>
            <span className='logo-xmr'>
              <img src='http://cdn.monerointegrations.com/logomonero.png'/>
            </span>
          <span className='xmr-payment-text-header'>
              <h2>MONERO PAYMENT</h2>
          </span>
        </div>
        <div className='content-xmr-payment'>
          <div className='xmr-amount-send'>
            <div className='xmr-amount-box mb-10'>
              <span className='xmr-label'>Send:</span>
              { payment?.amountXmr } XMR
            </div>
          </div>
          <div className='xmr-address mb-10'>
            <span className='xmr-label'>To this address:</span>
            <div className='xmr-address-box'>
              <Copy text={ payment?.integratedAddress || '' }/>
            </div>
          </div>
          <div className='xmr-qr-code'>
            <span className='xmr-label'>Or scan QR:</span>
            <div className='xmr-qr-code-box mt-10'>
              <img src={ `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${ payment?.address }` }/>
            </div>
          </div>
        </div>

      </div>

    </Modal>
  );
};

export default PaymentModal;
