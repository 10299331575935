import React from 'react';
import styles from './NameRatingAddress.module.scss';
import { Rating } from 'react-simple-star-rating';
import { IQuestionnaire } from '../../../../../types/questionnaires';

interface IProps {
  questionnaire?: IQuestionnaire | null;
}

const NameRatingAddress = ( { questionnaire }: IProps ) => {
  return (
    <div className={ styles.nameRatingAddress }>
      <div className={ styles.wrapper }>
        <div className={ styles.name }>{ questionnaire?.name }</div>
        <div className={ styles.rating }>
          <Rating
            readonly
            fillColor={ '#FF1178FF' }
            emptyColor={ '#fff' }
            size={ 25 }
            initialValue={ questionnaire?.rating }
          />
        </div>
      </div>
      <div className={ styles.address }>{ questionnaire?.city }, { questionnaire?.address }</div>
    </div>
  );
};

export default NameRatingAddress;
