import React from 'react';
import styles from './Layout.module.scss';
import ThemeDependence from '../common/ThemeDependence/ThemeDependence';
import Alert from '../common/Alert/Alert';
import Preloader from '../common/Preloader/Preloader';
import ClientAgeConfirm from '../common/Modal/ClientAgeConfirm/ClientAgeConfirm';

const Layout = () => {
  return (
    <>
      <div className={ styles.wrapper }>
        <div className={ styles.body }>
          <ClientAgeConfirm/>
          <Alert/>
          <Preloader/>
          <ThemeDependence/>
        </div>
      </div>
    </>
  );
};

export default Layout;
