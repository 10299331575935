import { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';

type TypeSetState<T> = Dispatch<SetStateAction<T>>

export interface IContext {
  theme: 'dark' | 'light';
  setTheme: TypeSetState<string>
}

const isDarkTheme = window?.matchMedia( '(prefers-color-scheme: dark)' ).matches;
const defaultTheme = isDarkTheme ? 'dark' : 'light';

export const ThemeContext = createContext<IContext>( {
  theme: 'dark',
  setTheme: () => {
  }
} );

export const ThemeProvider = ( { children }: any ) => {
  const themeVar = localStorage.getItem( 'app-theme' );
  const [ theme, setTheme ] = useState( themeVar === 'dark' || themeVar === 'light' ? themeVar : defaultTheme );

  const value = useMemo( () => ( { theme, setTheme } ), [ theme ] );

  return (
    //@ts-ignore
    <ThemeContext.Provider value={ value }>
      { children }
    </ThemeContext.Provider>
  );
};
