import React from 'react';
import styles from './InfoAboutMain.module.scss';
import { IQuestionnaire } from '../../../../types/questionnaires';
import { useLocation } from 'react-router-dom';
import AdditionalButtons from '../../AdditionalButtons/AdditionalButtons';
import NameRatingAddress from './NameRatingAddress/NameRatingAddress';
import ModelCharacteristics from './ModelCharacteristics/ModelCharacteristics';
import Tags from './Tags/Tags';
import { ICartProduct } from '../../../../types/cart';
import PricesTabs from './Prices/PricesTabs';

interface IProps {
  questionnaire: IQuestionnaire;
  hideButtons?: boolean;
  selected?: ICartProduct;
  setSelected?: ( value: ICartProduct ) => void;
}

const InfoAboutMain = ( { questionnaire, hideButtons, selected, setSelected }: IProps ) => {
  const location = useLocation();
  const showDisabledItems = location.pathname === '/profile/moderate';

  return (
    <>
      <div className={ styles.wrapper }>
        <NameRatingAddress questionnaire={ questionnaire }/>

        { !hideButtons && questionnaire &&
          <AdditionalButtons questionnaire={ questionnaire } selected={ selected }/>
        }
      </div>

      <ModelCharacteristics questionnaire={ questionnaire }/>

      <div className={ styles.tagsDesktop }>
        <Tags questionnaire={ questionnaire?.tags }/>
      </div>

      <PricesTabs
        questionnaire={ questionnaire }
        selected={ selected }
        setSelected={ setSelected }
      />

      {/*{ showDisabledItems &&*/ }
      {/*<div className={ styles.temporaryText }>{ localization.profile.choosePriceToSee } </div> }*/ }
      {/*{ showDisabledItems && <SexMenuDisabled/> }*/ }
    </>
  );
};

export default InfoAboutMain;
