import React, { DetailedHTMLProps } from 'react';
import styles from './ColoredButton.module.scss';
import cn from 'classnames';

interface IColoredButtonProps {
  text?: string;
  defaultValue?: string;
}

const ColoredButton = ( {
                          text,
                          className,
                          defaultValue,
                          ...props
                        }: IColoredButtonProps & DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> ) => {
  return (
    <button className={ cn( styles.button, className ) } { ...props }>
      { defaultValue ? defaultValue : text }
    </button>
  );
};

export default ColoredButton;
