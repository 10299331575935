import React from 'react';
import styles from './AllLayoutApp.module.scss';
import HeaderAll from '../../Headers/HeaderAll/HeaderAll';
import MainStack from '../../../navigator/MainStack';
import FooterAll from '../../Footer/FooterAll/FooterAll';
import ModalWrapper from '../../Modal/ModalWrapper';

const AllLayoutApp = () => {
  return (
    <div className={ styles.container }>

      <header className={ styles.header }>
        <HeaderAll/>
      </header>

      {/*<div className={ styles.notificationWrapper }>*/ }
      {/*  <img src={ notificationBtn } alt="" className={ styles.notificationBtn }/>*/ }
      {/*</div>*/ }

      <main className={ styles.main }>
        <MainStack/>
      </main>

      <footer className={ styles.footer }>
        <FooterAll/>
      </footer>

      <ModalWrapper/>
    </div>
  );
};

export default AllLayoutApp;
