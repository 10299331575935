import React from 'react';
import styles from './Button.module.scss';
import cn from 'classnames';
import { Link } from 'react-router-dom';

interface ISimpleButtonProps {
  to: string;
  text: string;
  className?: string;
  onClick?: any;
}

const LinkButton = ( { to, text, className, onClick }: ISimpleButtonProps ) => {
  return (
    <Link to={ to } className={ cn( styles.button, className ) } onClick={ onClick }>
      { text }
    </Link>
  );
};

export default LinkButton;
