import { apiGet, apiPost } from '../api';

export const getPromotions = async ( questionnaireId: string ) => {
  return await apiGet( { url: `/promotions/${ questionnaireId }` } );
};

export const buyPromotions = async ( questionnaireId: string, promoId: string | number, price: number ) => {
  return await apiPost( {
    url: `/promotions/${ questionnaireId }/buy/${ promoId }`,
    postData: {
      price,
    }
  } );
};
