import { apiGet, apiPost } from '../api';
import { IChatMessage, ICreateChatMessage } from '../types/chats';

export type ChatEvent = ( message: IChatMessage ) => void;

let socketSubscribes: ChatEvent[] = [];

export const addSubscription = ( callback: ChatEvent ) => {
  socketSubscribes.push( callback );
};

export const removeSubscription = ( callback: ChatEvent ) => {
  socketSubscribes = socketSubscribes.filter( ( c ) => c !== callback );
};

export const getSubscriptions = () => {
  return socketSubscribes;
};


export const getChat = async () => {
  return await apiGet( {
    url: '/chat',
  } );
};

export const sendMessage = async ( data: ICreateChatMessage | FormData ) => {
  return await apiPost( {
    url: '/chat',
    postData: data
  } );
};
