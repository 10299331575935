import React from 'react';
import { AiOutlineCopy } from 'react-icons/ai';
import cn from 'classnames';
import styles from './Copy.module.scss';

interface IProps {
  text: string;
}

const Copy = ( { text }: IProps ) => {
  const copy = () => {
    navigator?.clipboard?.writeText( text ).then();
  };

  return (
    <div className={ cn( styles.container ) }>
      { text }
      <button onClick={ copy } className={ cn( 'pointer', styles.button ) }>
        <AiOutlineCopy/>
      </button>
    </div>
  );
};

export default Copy;
