import React from 'react';
import styles from './ModelCharacteristics.module.scss';
import { localization } from '../../../../../data/localization';
import { IQuestionnaire } from '../../../../../types/questionnaires';

interface IProps {
  questionnaire?: IQuestionnaire | null;
}

const ModelCharacteristics = ( { questionnaire }: IProps ) => {
  return (
    <>
      <div className={ styles.characteristicsWrapper }>
        <div className={ styles.paragraph }>{ localization.profile.age } <span>{ questionnaire?.age || '' }</span>
        </div>
        <div className={ styles.paragraph }>{ localization.profile.tits }
          <span>{ questionnaire?.breastSize || '' }</span>
        </div>
        <div className={ styles.paragraph }>{ localization.profile.height }
          <span>{ questionnaire?.height || '' }</span>
        </div>
        <div className={ styles.paragraph }>{ localization.profile.weight }
          <span>{ questionnaire?.weight || '' }</span>
        </div>
      </div>

      <div className={ styles.additionalCharacteristicsWrapper }>
        { questionnaire?.attributeGroups?.map( group =>
          <React.Fragment key={ group.id }>
            { group.attributes.map( attribute =>
              <div key={ attribute.id } className={ styles.paragraphBottom }>
                { group.name }
                <span>{ attribute.name }</span>
              </div>
            ) }
          </React.Fragment>
        ) }
      </div>
    </>
  );
};

export default ModelCharacteristics;
