import React from 'react';
import styles from './HeaderAll.module.scss';
import sun from '../../../../static/imgs/icons/menuIcons/sun.svg';
import moon from '../../../../static/imgs/icons/menuIcons/moon.svg';
import { useTheme } from '../../../../hooks/useTheme';

interface IProps {
  useClass?: boolean;
}

const ThemeButton = ( { useClass }: IProps ) => {
  const { theme, setTheme } = useTheme();
  let classes = [ '', '' ];
  if ( useClass ) classes = [ styles.iconSun, styles.iconMoon ];

  return (
    <button className={ styles.menuItem }>
      { theme === 'dark'
        ? <img width="25" height="25" src={ sun } alt="Theme" onClick={ () => setTheme( 'light' ) }
               className={ classes[ 0 ] }/>
        : <img width="25" height="25" src={ moon } alt="Theme" onClick={ () => setTheme( 'dark' ) }
               className={ classes[ 1 ] }/>
      }
    </button>
  );
};

export default ThemeButton;
